import dayjs from "dayjs";

function calculateHourRow(serviceHours, date, offsetDay = 1) {
  function checkCustomHours(customDate) {
    let result = null;
    if (serviceHours.custom && serviceHours.custom.length) {
      let customOpening = serviceHours.custom.filter(element =>
        customDate.isSame(dayjs(element.beginDate), "day")
      );
      if (customOpening && customOpening.length > 0) {
        let condensedHours = customOpening.reduce(
          (accumulator, element, index) => {
            let hours = element.note
              ? element.note
              : `${element.beginHour} - ${element.endHour}`;
            return (
              accumulator +
              (index > 0 ? ", " : "") +
              "<span>" +
              hours +
              "</span>"
            );
          },
          ""
        );

        result = condensedHours;
      }
    }
    return result;
  }
  function checkDefaultHours(weekDay) {
    let result = null;
    if (serviceHours.default && serviceHours.default.length) {
      let defaultOpening = serviceHours.default.filter(
        element => element.beginWeekDay == weekDay + offsetDay
      );
      if (defaultOpening && defaultOpening.length > 0) {
        let condensedHours = defaultOpening.reduce((accumulator, element) => {
          let hours = element.note
            ? element.note
            : `${element.beginHour} - ${element.endHour}`;
          return (
            accumulator + "<span class='partial-hours'>" + hours + "</span>"
          );
        }, "");

        result = condensedHours;
      }
    }
    return result;
  }

  try {
    let customOpening = checkCustomHours(date);

    if (customOpening) {
      return customOpening;
    } else {
      let weekDay = date.day();
      let defaultHours = checkDefaultHours(weekDay);
      if (defaultHours) return defaultHours;
    }

    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
}

function isOpenRightNow(serviceHours, offsetDay = 1) {
  try {
    let today = dayjs();
    let todayHours = null;

    if (serviceHours.custom && serviceHours.custom.length) {
      todayHours = serviceHours.custom.find(element =>
        dayjs(element.beginDate).isSame(today, "day")
      );
    }
    if (!todayHours) {
      let weekDay = today.day() + offsetDay;
      todayHours = serviceHours.default.find(
        element => element.beginWeekDay == weekDay
      );
    }
    if (todayHours) {
      // check if is open
      let beginTime = dayjs(todayHours.beginHour, "HH:mm");
      let endTime = dayjs(todayHours.endHour, "HH:mm");

      if (today.isAfter(beginTime) && today.isBefore(endTime)) {
        return true;
      }
    }
    return false;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export { calculateHourRow, isOpenRightNow };
