var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.warehouse)?_c('v-container',{staticClass:"warehouse-detail",on:{"click":_vm.clicked}},[_c('h1',[_vm._v(_vm._s(_vm.$t("warehouse.title")))]),_c('v-row',{staticClass:"align-self-center mb-4",attrs:{"dense":""}},[_c('v-col',{staticClass:"warehouse-info-col",attrs:{"cols":"12","sm":"4","xl":"3"}},[_c('div',{staticClass:"px-4 warehouse-detail-column"},[_c('h1',[_vm._v(_vm._s(_vm.warehouse.name))]),_c('div',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.warehouse.address.address1)+" "+_vm._s(_vm.warehouse.address.addressNumber ? ", " + _vm.warehouse.address.addressNumber : null)+","),_c('br'),_vm._v(" "+_vm._s(_vm.warehouse.address.city)+" ("+_vm._s(_vm.warehouse.address.province)+") "+_vm._s(_vm.warehouse.address.postalcode)),_c('br'),_vm._v(" "+_vm._s(_vm.warehouse.address.country.name)+" ")])]),_c('v-divider'),(
          _vm.warehouse.metaData &&
            _vm.warehouse.metaData.warehouse_locator &&
            _vm.warehouse.metaData.warehouse_locator.DESCRIPTION
        )?_c('div',{staticClass:"px-4 py-2",domProps:{"innerHTML":_vm._s(_vm.warehouse.metaData.warehouse_locator.DESCRIPTION)}}):_vm._e(),_c('v-divider'),_c('div',{staticClass:"px-4"},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v("Orari")]),_c('WarehouseHours',{attrs:{"serviceHours":_vm.warehouse.serviceHours}})],1),_c('v-divider',{staticClass:"my-6"}),_c('flyer-slider',{attrs:{"warehouse-id":_vm.warehouse.warehouseId}}),_c('v-divider',{staticClass:"my-6"}),_c('div',{staticClass:"px-4"},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v("Servizi")]),_vm._l((_vm.warehouse.warehouseClass),function(service){return _c('div',{key:service.name,staticClass:"warehouse-service d-inline-block"},[(_vm.warehouseClassLinks[service.warehouseClassId])?_c('a',{attrs:{"href":_vm.warehouseClassLinks[service.warehouseClassId]}},[_c('v-img',{staticClass:"pdv-service-img",attrs:{"contain":"","src":service.iconSource,"alt":service.description,"title":service.description}})],1):_c('v-img',{staticClass:"pdv-service-img",attrs:{"contain":"","src":service.iconSource,"alt":service.description,"title":service.description}})],1)})],2)],1),_c('v-col',{staticClass:"map-col",attrs:{"cols":"12","sm":"8","xl":"9"}},[(_vm.warehouse.warehouseId)?_c('GmapMap',{ref:"mapRef",style:({ width: _vm.mapWidth }),attrs:{"center":{
          lat: _vm.warehouse.address.latitude,
          lng: _vm.warehouse.address.longitude
        },"zoom":15,"options":{
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUI: false
        }}},[_c('GmapMarker',{ref:_vm.warehouse.warehouseId,attrs:{"position":_vm.google &&
              new _vm.google.maps.LatLng(
                _vm.warehouse.address.latitude,
                _vm.warehouse.address.longitude
              ),"clickable":true,"icon":"/img_layout/map_pointer.png"}})],1):_vm._e()],1)],1),(
      _vm.warehouse.metaData &&
        _vm.warehouse.metaData.warehouse_locator &&
        _vm.warehouse.metaData.warehouse_locator.FOOTER_DESCRIPTION
    )?_c('div',{staticClass:"warehouse-footer-description pa-4",domProps:{"innerHTML":_vm._s(_vm.warehouse.metaData.warehouse_locator.FOOTER_DESCRIPTION)}}):_vm._e(),_c('v-btn',{staticClass:"primary",attrs:{"block":"","elevation":"0","href":"/punti-vendita"}},[_vm._v("Torna ai punti vendita")])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }