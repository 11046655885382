<template>
  <div class="flyer-slider">
    <swiper :options="swiperOption" ref="swiperRef">
      <swiper-slide
        v-for="flyer in flyers"
        :key="flyer.id"
        style=" width:290px "
      >
        <v-card :to="{ name: 'WarehouseFlyer', params: { flyerId: flyer.id } }">
          <v-img
            :src="getFlyerMedia(flyer).attributes.url"
            :width="getFlyerMedia(flyer).attributes.extras.width"
            :height="getFlyerMedia(flyer).attributes.extras.height"
          />
          <v-card-text>{{ flyer.attributes.title }}</v-card-text>
        </v-card>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import axios from "~/plugins/axios";

export default {
  name: "FlyerSlider",
  props: {
    warehouseId: { type: Number, required: true }
  },
  data() {
    return {
      flyers: [],
      media: [],
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 5,
        pagination: {
          el: `#flyer-slider-pagination-${this.warehouseId}`,
          clickable: true
        },

        navigation: {
          nextEl: `#flyer-slider-next-${this.warehouseId}`,
          prevEl: `#flyer-slider-prev-${this.warehouseId}`
        }
      }
    };
  },
  methods: {
    async reload() {
      // https://api-vw-static.shopfully.cloud/v1/it_it/5bbb3ae4-a124-4e51-b0cd-63daac1f41c0/${this.warehouseId}/flyers.json
      let response = await axios.get("/ebsn/api/shopfully/view", {
        params: {
          warehouse_id: this.warehouseId
        }
      });
      this.flyers = response.data.data.value.data;
      this.media = response.data.data.value.included;
    },
    getFlyerMedia(flyer) {
      return this.media.find(
        m => m.id === flyer.relationships.cover.data[2].id
      );
    }
  },
  mounted() {
    this.reload();
  }
};
</script>
